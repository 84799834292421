const fileViewRequested = () => ({
  type: 'FETCH_FILE_VIEW_REQUEST',
});

const fileViewLoaded = partial => ({
  type: 'FETCH_FILE_VIEW_SUCCESS',
  payload: partial,
});

const fileViewError = error => ({
  type: 'FETCH_FILE_VIEW_FAILURE',
  payload: error,
});

export const fetchFileView = (
  api,
  dispatch,
  fileId,
  partId = '',
) => async () => {
  dispatch(fileViewRequested());
  try {
    const res = await api.getFileContent(fileId, partId);
    dispatch(fileViewLoaded(res));
  } catch (error) {
    dispatch(fileViewError(error));
  }
};

export const openFileViewer = file => ({
  type: 'OPEN_FILE_VIEWER',
  payload: file,
});

export const closeFileViewer = () => ({
  type: 'CLOSE_FILE_VIEWER',
});

export const toggleHighlight = checked => ({
  type: 'TOGGLE_HIGHLIGHT',
  payload: checked,
});

export const fontSizePlus = () => ({
  type: 'SET_FONT_SIZE_PLUS',
});

export const fontSizeMinus = () => ({
  type: 'SET_FONT_SIZE_MINUS',
});

export const fontSizeReset = () => ({
  type: 'SET_FONT_SIZE_RESET',
});

export const jumpPrevMark = () => ({
  type: 'JUMP_TO_PREV_MARK',
});

export const jumpNextMark = () => ({
  type: 'JUMP_TO_NEXT_MARK',
});

export const selectMark = markId => ({
  type: 'SELECT_MARK',
  payload: markId,
});

export const unselectMark = () => ({
  type: 'UNSELECT_MARK',
});

export const countTotalMark = () => {
  const count = document.querySelectorAll('#viewer mark').length;
  return {
    type: 'COUNT_TOTAL_MARKS',
    payload: count,
  };
};
