/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  languages: [],
  pending: true,
  error: null,
};

const updateLanguageList = createReducer(initialState, {
  FETCH_LANGUAGES_REQUEST: () => initialState,
  FETCH_LANGUAGES_SUCCESS: (state, action) => {
    state.languages = action.payload.data;
    state.pending = false;
    state.error = action.payload.status === 'error' && action.payload.message;
  },
  FETCH_LANGUAGES_FAILURE: (state, action) => {
    state.error = action.payload;
  },
});

export default updateLanguageList;
