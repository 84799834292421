/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  password: '',
  username: '',
  timezone: new Date().getTimezoneOffset() / -60,
  pending: false,
  error: false,
  token: localStorage.getItem('token') || null,
};

const user = createReducer(initialState, {
  FETCH_USER_REQUEST: state => {
    state.pending = true;
  },
  FETCH_USER_SUCCESS: (state, action) => ({
    ...state,
    ...action.payload.data,
    pending: false,
    error: action.payload.status === 'error' && action.payload.message,
  }),
  FETCH_USER_FAILURE: (state, action) => {
    state.pending = false;
    state.error = action.payload.message;
  },
  CHANGE_USER_PASSWORD_FIELD: (state, action) => {
    state.password = action.payload;
  },
  USER_SIGN_OUT: () => initialState,
});

export default user;
