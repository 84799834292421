import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';

const FileSearchBox = ({ fileSearchChange, value }) => (
  <InputGroup size="lg" className="mb-3">
    <InputGroup.Prepend>
      <InputGroup.Text>
        <FaSearch />
      </InputGroup.Text>
    </InputGroup.Prepend>
    <Form.Control
      type="search"
      placeholder="Search File"
      onChange={fileSearchChange}
      value={value}
    />
  </InputGroup>
);

export default FileSearchBox;
