import React from 'react';
import { ProgressBar } from 'react-bootstrap';

const FileStatus = React.memo(({ progress, status }) => {
  switch (status) {
    case 'processed':
      return <ProgressBar variant="success" now="100" label="Processed" />;
    case 'failed':
      return <ProgressBar variant="danger" now="100" label="Failed" />;
    case 'deleting':
      return (
        <ProgressBar animated variant="danger" now="100" label="Deleting" />
      );
    case 'pending':
      return <ProgressBar variant="secondary" now="100" label="Pending" />;
    case 'processing':
      return (
        <ProgressBar
          animated
          variant="primary"
          now={progress}
          label={`${progress}%`}
        />
      );
    default:
      return <ProgressBar variant="secondary" now="100" label="Unknown" />;
  }
});

export default FileStatus;
