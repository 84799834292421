import React from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import { FaRegCheckCircle } from 'react-icons/fa';
import FileUploadFileSelector from './components/FileUploadFileSelector';

const FileUpload = React.memo(
  ({
    onAddUrlInput,
    onSetUrlInput,
    onDelUrlInput,
    onSetFileInput,
    onClearUrlInput,
    onClaerAllUrlInputs,
    onUpload,
    urlField,
    fileField,
    languages,
    selectedLanguages,
    onChangeLanguageSelect,
  }) => {
    const fileInput = React.createRef();
    const fileLimit = 5;

    const onFileSelect = event => {
      onClearUrlInput(urlField[0].id);
      if (fileInput.current.files.length > 1) {
        onSetFileInput('Multiple local files');
      } else {
        onSetFileInput(event.target.files[0].name);
      }
      document.getElementById(`${languages[0]}-checkbox`).focus();
    };

    const handleAddField = () => {
      onAddUrlInput();
    };

    const handleRemoveField = id => {
      onDelUrlInput(id);
    };

    const onReset = id => {
      onClearUrlInput(id);
      if (urlField[0].id === id) {
        fileInput.current.value = null;
      }
    };

    const onSubmit = event => {
      event.preventDefault();
      const data = new FormData();
      const urls = urlField.reduce((acc, item) => {
        if (item.url) {
          return [...acc, item.url];
        }
        return acc;
      }, []);
      data.append('languages', selectedLanguages.join(', '));
      data.append('data', JSON.stringify(urls));
      if (fileField) {
        Object.values(fileInput.current.files).forEach(file => {
          data.append(`file_${file.name}`, file, file.name);
        });
      }
      onUpload(data);

      onClaerAllUrlInputs();
    };

    return (
      <div className="fileUpload mb-5">
        <Form encType="multipart/form-data">
          <Form.Row className="mb-2">
            {urlField.map((value, i, arr) => (
              <FileUploadFileSelector
                fileField={fileField}
                onAddField={handleAddField}
                urlField={value.url}
                onFileSelect={onFileSelect}
                onRemoveField={handleRemoveField}
                onReset={onReset}
                onSetUrlInput={onSetUrlInput}
                ref={fileInput}
                addButton={i === arr.length - 1 && i < fileLimit - 1}
                fileButton={i === 0}
                removeButton={i !== 0}
                key={value.id}
                id={value.id}
              />
            ))}
          </Form.Row>
          <Form.Row className="d-flex justify-content-between">
            <Col className="d-flex align-items-stretch text-white">
              <Form.Group className="d-flex flex-wrap align-items-center">
                {languages.map(language => (
                  <Form.Check
                    key={language.toLowerCase()}
                    id={`${language}-checkbox`}
                    inline
                    custom
                    label={language}
                    value={language}
                    type="checkbox"
                    checked={selectedLanguages.includes(language)}
                    onChange={onChangeLanguageSelect}
                    className="my-1"
                  />
                ))}
              </Form.Group>
            </Col>
            <Col md="auto" className="d-flex align-items-center">
              <Form.Group>
                <Button
                  disabled={
                    !(
                      (fileField && selectedLanguages.length > 0) ||
                      (urlField.find(el => el.url.length > 0) &&
                        selectedLanguages.length > 0)
                    )
                  }
                  type="submit"
                  variant="success"
                  size="lg"
                  className="btn-block w-100 d-flex justify-content-center align-items-center"
                  onClick={onSubmit}
                >
                  <FaRegCheckCircle />
                  &nbsp;Upload
                </Button>
              </Form.Group>
            </Col>
          </Form.Row>
        </Form>
      </div>
    );
  },
);

export default FileUpload;
