import updateFileList from './files';
import user from './user';
import updateLanguageList from './languages';
import updateFileView from './viewer';

const reducer = {
  user,
  fileList: updateFileList,
  languageList: updateLanguageList,
  fileViewer: updateFileView,
};

export default reducer;
