import React from 'react';
import { Spinner } from 'react-bootstrap';

const Init = () => (
  <div className="init text-center">
    <Spinner
      animation="border"
      as="span"
      variant="light"
      style={{ width: '5rem', height: '5rem' }}
    />
  </div>
);

export default Init;
