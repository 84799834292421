import React from 'react';

const FileSize = React.memo(({ size }) => {
  const humanFileSize = bytesSize => {
    if (bytesSize < 1024) return `${bytesSize} B`;
    const i = Math.floor(Math.log(bytesSize) / Math.log(1024));
    let num = bytesSize / 1024 ** i;
    const round = Math.round(num);
    if (round < 10) {
      num = num.toFixed(2);
    } else if (round < 100) {
      num = num.toFixed(1);
    } else {
      num = round;
    }
    return `${num} ${'KMGTPEZY'[i - 1]}B`;
  };
  return <>{humanFileSize(size)}</>;
});

export default FileSize;
