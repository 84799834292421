import React, { Component } from 'react';
import { connect } from 'react-redux';
import WithService from '../../components/WithService';
import {
  fetchFileView,
  toggleHighlight,
  fontSizePlus,
  fontSizeMinus,
  fontSizeReset,
  jumpPrevMark,
  jumpNextMark,
  selectMark,
  unselectMark,
  countTotalMark,
  closeFileViewer,
} from '../../redux/actions';
import compose from '../../utils';
import ViewerToolbar from '../../components/ViewerToolbar';
import ViewerContent from '../../components/ViewerContent/ViewerContent';
import ErrorViewer from '../../components/ErrorViewer';

const mapStateToProps = ({
  fileViewer: {
    fileId,
    status,
    title,
    progress,
    highlight,
    fontSize,
    jumperMarkId,
    content,
    pending,
    error,
  },
}) => ({
  fileId,
  title,
  status,
  progress,
  highlight,
  fontSize,
  jumperMarkId,
  content,
  pending,
  error,
});

const mapDispatchToProps = (dispatch, { api }) => ({
  onFileView: (fileId, partId) =>
    dispatch(fetchFileView(api, dispatch, fileId, partId)),
  onToggleHighlight: event => dispatch(toggleHighlight(event.target.checked)),
  onCloseFileViewer: () => dispatch(closeFileViewer()),
  onFontSizePlus: () => dispatch(fontSizePlus()),
  onFontSizeMinus: () => dispatch(fontSizeMinus()),
  onFontSizeReset: () => dispatch(fontSizeReset()),
  onJumpPrevMark: () => dispatch(jumpPrevMark()),
  onJumpNextMark: () => dispatch(jumpNextMark()),
  onSelectMark: markId => dispatch(selectMark(markId)),
  onUnselectMark: () => dispatch(unselectMark()),
  onCountTotalMark: () => dispatch(countTotalMark()),
});

class ViewerContainer extends Component {
  componentDidMount() {
    const { onFileView, fileId } = this.props;
    onFileView(fileId);
  }

  componentDidUpdate(prevProps) {
    const {
      onFileView,
      onCountTotalMark,
      fileId,
      status,
      content,
      jumperMarkId,
    } = this.props;
    if (status === 'processing' && !this.fileViewUpdateInterval) {
      this.fileViewUpdateInterval = setInterval(() => {
        onFileView(fileId, this.nextPartId());
      }, 10000);
    }
    if (status !== 'processing' && this.fileViewUpdateInterval) {
      clearInterval(this.fileViewUpdateInterval);
    }
    if (prevProps.jumperMarkId !== jumperMarkId) {
      this.delMarkSelectedClass(prevProps.jumperMarkId);
      this.setMarkSelectedClass(jumperMarkId);
      this.marksJumpToId(jumperMarkId);
    }
    if (Object.keys(prevProps.content).length !== Object.keys(content).length) {
      this.setMarksClick();
      onCountTotalMark();
    }
  }

  componentWillUnmount() {
    const { onCloseFileViewer } = this.props;
    clearInterval(this.fileViewUpdateInterval);
    onCloseFileViewer();
  }

  nextPartId = () => {
    const { content } = this.props;
    const ids = Object.getOwnPropertyNames(content);
    return Math.max(...ids) + 1;
  };

  scrollYTo = (elem, offsetTop) => {
    const bodyRect = document.body.getBoundingClientRect().top;
    const elemRect = elem.getBoundingClientRect().top;
    const elemPosition = elemRect - bodyRect;
    const offPosition = elemPosition - offsetTop;
    window.scroll({
      top: offPosition,
      behavior: 'smooth',
    });
  };

  delMarkSelectedClass = id => {
    const mark = document.querySelector(`mark#mark-${id}`);
    if (mark) {
      mark.classList.remove('selected');
    }
  };

  setMarkSelectedClass = id => {
    const mark = document.querySelector(`mark#mark-${id}`);
    if (mark) {
      mark.classList.add('selected');
    }
  };

  marksJumpToId = id => {
    const mark = document.querySelector(`mark#mark-${id}`);
    if (mark) {
      mark.classList.add('selected');
      this.scrollYTo(document.querySelector('mark.selected'), 300);
    }
  };

  setMarksClick = () => {
    // UGLY - TO BE REFACTORED
    const { onSelectMark, onUnselectMark } = this.props;
    document.querySelectorAll('#viewer mark').forEach(mark => {
      if (!mark.getAttribute('listener')) {
        mark.addEventListener('click', () => {
          const { jumperMarkId } = this.props;
          const markId = Number.parseInt(mark.id.slice(5), 10);
          if (markId !== jumperMarkId) {
            onSelectMark(markId);
          } else {
            onUnselectMark();
          }
        });
        mark.setAttribute('listener', true);
      }
    });
  };

  render() {
    const {
      status,
      progress,
      title,
      highlight,
      fontSize,
      content,
      error,
      onFontSizePlus,
      onFontSizeMinus,
      onFontSizeReset,
      onToggleHighlight,
      onCloseFileViewer,
      onJumpPrevMark,
      onJumpNextMark,
    } = this.props;

    // UGLY - TO BE REFACTORED
    document.onkeydown = event => {
      const keycode = event.which || event.keyCode;
      switch (keycode) {
        case 39:
          event.preventDefault();
          onJumpNextMark();
          break;
        case 37:
          event.preventDefault();
          onJumpPrevMark();
          break;
        default:
          return event;
      }
      return event;
    };

    if (error) {
      return <ErrorViewer error={error} />;
    }
    return (
      <div id="viewer" className="bg-white my-3 p-3 border rounded">
        <ViewerToolbar
          onFontSizePlus={onFontSizePlus}
          onFontSizeMinus={onFontSizeMinus}
          onFontSizeReset={onFontSizeReset}
          onJumpPrevMark={onJumpPrevMark}
          onJumpNextMark={onJumpNextMark}
          onToggleHighlight={onToggleHighlight}
          onCloseFileViewer={onCloseFileViewer}
          status={status}
          progress={progress}
          title={title}
        />
        <ViewerContent
          content={content}
          highlight={highlight}
          fontSize={fontSize}
          status={status}
        />
      </div>
    );
  }
}

export default compose(
  WithService(),
  connect(mapStateToProps, mapDispatchToProps),
)(ViewerContainer);
