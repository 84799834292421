import React from 'react';
import User from '../User';
// import ViewerModal from '../ViewerModal';
import ViewerContainer from '../../containers/ViewerContainer';
import FileUploadContainer from '../../containers/FileUploadContainer';
import FileListContainer from '../../containers/FilesContainer';

const Home = ({ viewer, user }) => {
  if (viewer) {
    return <ViewerContainer />;
  }
  return (
    <>
      <User />
      {user.id && (
        <>
          <FileUploadContainer />
          <FileListContainer />
        </>
      )}
    </>
  );
};

export default Home;
