import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Form, InputGroup } from 'react-bootstrap';
import { FaLock } from 'react-icons/fa';
import WithService from '../WithService';
import compose from '../../utils';
import {
  fetchUserSignIn,
  setUserPasswordInput,
  userSignOut,
} from '../../redux/actions';
import ErrorViewer from '../ErrorViewer';

class User extends Component {
  onSignIn = event => {
    const { onUserSignIn, user } = this.props;
    const { password, timezone } = user;
    event.preventDefault();
    onUserSignIn({ password, timezone });
  };

  render() {
    const { user, onSetUserPasswordInput, onUserSignOut } = this.props;
    const { id, password, error } = user;

    if (!process.env.REACT_APP_DISABLE_AUTH && !id) {
      return (
        <div className="signin">
          <Form>
            <Form.Row>
              <Col md className="d-none">
                <InputGroup className="mb-2">
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    size="lg"
                    autoComplete="username"
                  />
                </InputGroup>
              </Col>
              <Col md>
                <InputGroup className="mb-2">
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FaLock />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    id="passwordInput"
                    className="text-center text-md-left"
                    size="lg"
                    type="password"
                    placeholder="Password"
                    autoComplete="current-password"
                    value={password}
                    onChange={onSetUserPasswordInput}
                  />
                </InputGroup>
              </Col>
              <Col md="auto">
                <Button
                  variant="primary"
                  type="submit"
                  size="lg"
                  className="btn-block w-100 mb-2"
                  onClick={this.onSignIn}
                >
                  Sign In
                </Button>
              </Col>
            </Form.Row>
          </Form>
          {error && <ErrorViewer error={error} />}
        </div>
      );
    }
    if (!process.env.REACT_APP_DISABLE_AUTH) {
      return (
        <Button className="my-3" variant="primary" onClick={onUserSignOut}>
          SignOut
        </Button>
      );
    }
    return <div className="mt-3" />;
  }
}

const mapStateToProps = ({ user }) => ({
  user,
});

const mapDispatchToProps = (dispatch, { api }) => ({
  onUserSignIn: password => dispatch(fetchUserSignIn(api, dispatch, password)),
  onSetUserPasswordInput: event =>
    dispatch(setUserPasswordInput(event.target.value)),
  onUserSignOut: () => dispatch(userSignOut()),
});

export default compose(
  WithService(),
  connect(mapStateToProps, mapDispatchToProps),
)(User);
