import React, { Component } from 'react';
import { connect } from 'react-redux';
import WithService from '../../components/WithService';
import {
  fetchFiles,
  setFileSearchInput,
  openFileViewer,
  fetchFileDownload,
  openFileDeleteModal,
  closeFileDeleteModal,
  onFileDeleteFromModal,
} from '../../redux/actions';
import compose from '../../utils';
import ErrorViewer from '../../components/ErrorViewer';
import FileSearchBox from '../../components/FileSearchBox';
import FileList from '../../components/FileList';
import FileDeleteModal from '../../components/FileDeleteModal';

const mapStateToProps = ({
  fileList: { files, pending, error, searchField, deleteModal },
  fileViewer: { opened },
}) => ({ files, pending, error, searchField, deleteModal, opened });

const mapDispatchToProps = (dispatch, { api }) => ({
  getFiles: fetchFiles(api, dispatch),
  onOpenFileViewer: fileId => dispatch(openFileViewer(fileId)),
  onFileSearchChange: event => dispatch(setFileSearchInput(event.target.value)),
  onFileDownload: (fileId, fileTitle) =>
    fetchFileDownload(api, dispatch, fileId, fileTitle),
  onOpenFileDeleteModal: fileId => dispatch(openFileDeleteModal(fileId)),
  onCloseFileDeleteModal: () => dispatch(closeFileDeleteModal()),
  onFileDelete: fileId => onFileDeleteFromModal(api, dispatch, fileId),
});

class FileListContainer extends Component {
  async componentDidMount() {
    const { getFiles } = this.props;
    await getFiles();
    this.getFilesInterval = setInterval(async () => {
      await getFiles();
    }, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.getFilesInterval);
  }

  render() {
    const {
      onFileDownload,
      onFileDelete,
      onOpenFileViewer,
      searchField,
      onFileSearchChange,
      onOpenFileDeleteModal,
      onCloseFileDeleteModal,
      files,
      error,
      deleteModal,
      opened,
    } = this.props;

    if (error) {
      return <ErrorViewer error={error} />;
    }

    const filteredFiles = Object.values(files).filter(file =>
      file.title.toLowerCase().includes(searchField.toLowerCase()),
    );

    return (
      <>
        <FileSearchBox
          fileSearchChange={onFileSearchChange}
          value={searchField}
        />
        <FileList
          onFileDownload={onFileDownload}
          onOpenFileDeleteModal={onOpenFileDeleteModal}
          onOpenFileViewer={onOpenFileViewer}
          files={filteredFiles}
          deleteModal={deleteModal}
          viewerOpened={opened}
        />
        {deleteModal && (
          <FileDeleteModal
            deleteModal={deleteModal}
            delFile={this.delFile}
            title={files[deleteModal].title}
            onCloseFileDeleteModal={onCloseFileDeleteModal}
            onFileDelete={onFileDelete}
          />
        )}
      </>
    );
  }
}

export default compose(
  WithService(),
  connect(mapStateToProps, mapDispatchToProps),
)(FileListContainer);
