import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Container } from 'react-bootstrap';
import './styles/index.scss';
import App from './containers/App';
import ErrorBoundry from './components/ErrorBoundry';
import ApiService from './services/api';
import { ServiceProvider } from './components/ServiceContext';
import store from './redux/store';
import * as serviceWorker from './serviceWorker';

const api = new ApiService();

render(
  <Provider store={store}>
    <div
      className="d-flex w-100 mx-auto flex-column"
      style={{ scrollBehavior: 'smooth', minHeight: '100%' }}
    >
      <Container className="App mt-auto mb-auto">
        <ErrorBoundry>
          <ServiceProvider value={api}>
            <App />
          </ServiceProvider>
        </ErrorBoundry>
      </Container>
    </div>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
