import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const FileDeleteModal = ({
  onFileDelete,
  title,
  deleteModal,
  onCloseFileDeleteModal,
}) => (
  <Modal show animation={false} onHide={() => onCloseFileDeleteModal()}>
    <Modal.Header>
      <Modal.Title className="text-break">Delete confirmation</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        You are about to delete File from Server, this procedure is
        irreversible. Do you want to proceed? Delete File:
        <br />
        <strong className="text-break">&quot;{title}&quot;</strong>
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={() => onCloseFileDeleteModal()}>Cancel</Button>
      <Button variant="danger" onClick={() => onFileDelete(deleteModal)}>
        Delete
      </Button>
    </Modal.Footer>
  </Modal>
);

export default FileDeleteModal;
