/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  opened: false,
  fileId: null,
  title: null,
  status: null,
  progress: null,
  highlight: true,
  fontSize: 14,
  jumperMarkId: null,
  totalMarks: null,
  content: {},
  pending: true,
  error: null,
};

const updateFileView = createReducer(initialState, {
  FETCH_FILE_VIEW_REQUEST: state => {
    state.pending = true;
  },
  FETCH_FILE_VIEW_SUCCESS: (state, action) => {
    const { id, title, status, progress, content } = action.payload.data;
    state.title = title;
    state.status = status;
    state.progress = progress;
    state.content[id] = content;
    state.pending = false;
    state.error = action.payload.status === 'error' && action.payload.message;
  },
  FETCH_FILE_VIEW_FAILURE: (state, action) => {
    state.pending = false;
    state.error = action.payload.message;
  },
  OPEN_FILE_VIEWER: (state, action) => {
    state.opened = true;
    state.fileId = action.payload;
  },
  CLOSE_FILE_VIEWER: () => initialState,
  TOGGLE_HIGHLIGHT: (state, action) => {
    state.highlight = action.payload;
  },
  SET_FONT_SIZE_PLUS: state => {
    state.fontSize += 2;
  },
  SET_FONT_SIZE_MINUS: state => {
    state.fontSize -= 2;
  },
  SET_FONT_SIZE_RESET: state => {
    state.fontSize = initialState.fontSize;
  },
  JUMP_TO_PREV_MARK: state => {
    if (state.jumperMarkId > 1) {
      state.jumperMarkId -= 1;
    }
  },
  JUMP_TO_NEXT_MARK: state => {
    if (state.jumperMarkId < state.totalMarks) {
      state.jumperMarkId += 1;
    }
  },
  SELECT_MARK: (state, action) => {
    state.jumperMarkId = action.payload;
  },
  UNSELECT_MARK: state => {
    state.jumperMarkId = null;
  },
  COUNT_TOTAL_MARKS: (state, action) => {
    state.totalMarks = action.payload;
  },
});

export default updateFileView;
