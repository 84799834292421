const languagesRequested = () => ({
  type: 'FETCH_LANGUAGES_REQUEST',
});

const languagesLoaded = languages => ({
  type: 'FETCH_LANGUAGES_SUCCESS',
  payload: languages,
});

const languagesError = error => ({
  type: 'FETCH_LANGUAGES_FAILURE',
  payload: error,
});

const fetchLanguages = (api, dispatch) => () => {
  dispatch(languagesRequested());
  api
    .getLanguages()
    .then(data => dispatch(languagesLoaded(data)))
    .catch(err => dispatch(languagesError(err)));
};

export default fetchLanguages;
