import React, { forwardRef, memo } from 'react';
import { Button, Col, Form, InputGroup } from 'react-bootstrap';
import {
  FaRegHdd,
  FaRegTimesCircle,
  FaRegPlusSquare,
  FaRegMinusSquare,
} from 'react-icons/fa';

const FileUploadFileSelector = forwardRef(
  (
    {
      fileField,
      urlField,
      addButton,
      fileButton,
      id,
      removeButton,
      onAddField,
      onFileSelect,
      onRemoveField,
      onReset,
      onSetUrlInput,
    },
    fileInput,
  ) => (
    <Col xs={12} className="mb-2" id={id}>
      <InputGroup>
        {addButton && (
          <InputGroup.Prepend>
            <Button
              variant="primary"
              size="lg"
              className="btn-block w-100"
              onClick={onAddField}
            >
              <FaRegPlusSquare />
            </Button>
          </InputGroup.Prepend>
        )}
        {removeButton && (
          <InputGroup.Prepend>
            <Button
              variant="danger"
              size="lg"
              className="btn-block w-100"
              onClick={() => onRemoveField(id)}
            >
              <FaRegMinusSquare />
            </Button>
          </InputGroup.Prepend>
        )}
        {fileButton && (
          <InputGroup.Prepend>
            <Form.Label
              className={
                fileField
                  ? 'btn btn-lg btn-success btn-block w-100 mb-0'
                  : 'btn btn-lg btn-secondary btn-block w-100 mb-0'
              }
            >
              <FaRegHdd />
              <input
                type="file"
                ref={fileInput}
                hidden
                multiple
                onClick={() => onReset(id)}
                onChange={onFileSelect}
              />
            </Form.Label>
          </InputGroup.Prepend>
        )}
        <Form.Control
          disabled={fileButton && fileField}
          className="text-center text-md-left"
          size="lg"
          placeholder={
            fileButton && fileField
              ? `Local file: ${fileField}`
              : 'Link to Document'
          }
          value={urlField}
          onChange={e => onSetUrlInput(id, e)}
        />

        <InputGroup.Append>
          <Button
            variant="danger"
            size="lg"
            className="btn-block w-100"
            onClick={() => onReset(id)}
          >
            <FaRegTimesCircle />
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </Col>
  ),
);

export default memo(FileUploadFileSelector);
