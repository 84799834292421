const filesRequested = () => ({
  type: 'FETCH_FILES_REQUEST',
});

const filesLoaded = files => ({
  type: 'FETCH_FILES_SUCCESS',
  payload: files,
});

const filesError = error => ({
  type: 'FETCH_FILES_FAILURE',
  payload: error,
});

export const fetchUpload = (api, dispatch, data) => async () => {
  dispatch(filesRequested());
  try {
    const res = await api.uploadFile(data);
    dispatch(filesLoaded(res));
  } catch (error) {
    dispatch(filesError(error));
  }
};

export const fetchFiles = (api, dispatch) => async () => {
  dispatch(filesRequested());
  try {
    const res = await api.getFiles();
    dispatch(filesLoaded(res));
  } catch (error) {
    dispatch(filesError(error));
  }
};

const fetchFileDelete = (api, dispatch, id) => async () => {
  dispatch(filesRequested());
  try {
    const res = await api.delFile(id);
    dispatch(filesLoaded(res));
  } catch (error) {
    dispatch(filesError(error));
  }
};

export const addUrlInput = () => ({
  type: 'ADD_URL_FIELD',
});

export const setUrlInput = (id, url) => ({
  type: 'CHANGE_URL_FIELD',
  payload: {
    id,
    url,
  },
});

export const delUrlInput = id => ({
  type: 'DEL_URL_FIELD',
  payload: id,
});

export const setFileInput = blob => ({
  type: 'CHANGE_FILE_FIELD',
  payload: blob,
});

export const clearUrlInput = id => ({
  type: 'CLEAR_FIELD',
  payload: id,
});

export const claerAllUrlInputs = () => ({
  type: 'CLEAR_ALL_URL_INPUTS',
});

const setLanguageSelect = language => ({
  type: 'CHECK_LANGUAGE_SELECT',
  payload: language,
});

const unsetLanguageSelect = language => ({
  type: 'UNCHECK_LANGUAGE_SELECT',
  payload: language,
});

export const changeLanguageSelect = (dispatch, event) => {
  if (event.target.checked) {
    dispatch(setLanguageSelect(event.target.value));
  } else {
    dispatch(unsetLanguageSelect(event.target.value));
  }
};

export const setFileSearchInput = text => ({
  type: 'CHANGE_FILE_SEARCH_FIELD',
  payload: text,
});

const fileDownload = id => ({
  type: 'FETCH_FILE_DOWNLOAD',
  payload: id,
});

export const fetchFileDownload = (api, dispatch, fileId, fileTitle) => {
  dispatch(fileDownload(fileId));
  api.getFileDownload(fileId, fileTitle);
};

// File Delete Modal Component Actions

export const openFileDeleteModal = id => ({
  type: 'OPEN_FILE_DELETE_MODAL',
  payload: id,
});

export const closeFileDeleteModal = () => ({
  type: 'CLOSE_FILE_DELETE_MODAL',
});

const fileSetDeletingStatus = fileId => ({
  type: 'SET_FILE_DELETING_STATUS',
  payload: fileId,
});

export const onFileDeleteFromModal = (api, dispatch, fileId) => {
  dispatch(fileSetDeletingStatus(fileId));
  dispatch(closeFileDeleteModal());
  dispatch(fetchFileDelete(api, dispatch, fileId));
};
