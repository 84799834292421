import React from 'react';
import { Spinner } from 'react-bootstrap';
import ViewerContentPartial from '../ViewerContentPartial';
import './style.scss';

const ViewerContent = React.memo(({ content, highlight, fontSize, status }) => (
  <div
    className={`border border-light p-2 ${highlight ? '' : 'no-highlight'}`}
    style={{ fontSize }}
  >
    {Object.keys(content).map(id => {
      const partial = content[id];
      return <ViewerContentPartial partial={partial} key={id} />;
    })}
    {status === 'processing' && (
      <div className="text-center align-middle m-2">
        <Spinner animation="border" size="sm" /> Processing...
      </div>
    )}
  </div>
));

export default ViewerContent;
