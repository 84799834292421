import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import FileStatus from '../FileStatus';
import FileSize from '../FileSize';
import FileModified from '../FileModified';
import FileButtons from '../FileButtons';
import './FileList.scss';

const FileList = ({
  files,
  onFileDownload,
  onOpenFileDeleteModal,
  onOpenFileViewer,
}) => {
  const sort = [
    {
      dataField: 'modified',
      order: 'desc',
    },
  ];

  const statusFormatter = (cell, row) => (
    <FileStatus progress={row.progress} status={cell} />
  );

  const sizeFormatter = cell => <FileSize size={cell} />;

  const modifiedFormatter = cell => <FileModified modified={cell} />;

  const actionsFormatter = (cell, row) => (
    <FileButtons
      onOpenFileViewer={onOpenFileViewer}
      downloadFile={onFileDownload}
      onOpenFileDeleteModal={onOpenFileDeleteModal}
      status={row.status}
      id={row.id}
      title={row.title}
    />
  );

  const rowClasses = row => {
    let classes = '';
    if (row.status === 'deleting') {
      classes = 'table-danger text-muted';
    }
    return classes;
  };

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      hidden: true,
    },
    {
      dataField: 'title',
      text: 'Title',
      classes: 'align-middle text-break  w-50',
      style: { minWidth: '200px', maxWidth: '350px' },
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      classes: 'align-middle w-50',
      style: {
        minWidth: '100px',
      },
      sort: true,
      formatter: statusFormatter,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === 'asc') {
          if (a !== b) {
            return a.localeCompare(b);
          }
          return rowA.progress - rowB.progress;
        }
        if (a !== b) {
          return b.localeCompare(a);
        }
        return rowB.progress - rowA.progress;
      },
    },
    {
      dataField: 'size',
      text: 'Size',
      classes: 'text-nowrap align-middle',
      sort: true,
      formatter: sizeFormatter,
    },
    {
      dataField: 'modified',
      text: 'Modified',
      classes: 'text-nowrap align-middle',
      sort: true,
      formatter: modifiedFormatter,
    },
    {
      dataField: 'actions',
      text: 'Actions',
      classes: 'text-nowrap align-middle',
      formatter: actionsFormatter,
      style: {
        width: '1px',
      },
    },
  ];

  return (
    <div className="FileList bg-white p-3 my-3 border rounded">
      <BootstrapTable
        classes="table-striped table-lg table-responsive-lg table-fit"
        keyField="id"
        data={files}
        columns={columns}
        bordered={false}
        rowClasses={rowClasses}
        defaultSorted={sort}
        bootstrap4
      />
    </div>
  );
};

export default FileList;
