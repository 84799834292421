import {
  API_BASE,
  API_USER,
  API_LANGUAGES,
  API_FILES,
  DISABLE_AUTH,
} from '../config';

if (DISABLE_AUTH) {
  localStorage.setItem('token', 'aaaaaaa.bbbbbbbb.ccccccc');
}

export default class ApiService {
  httpRequest = async (
    path,
    method,
    data = false,
    contentType = 'application/json',
  ) => {
    const headers = new Headers({
      Authorization:
        localStorage.getItem('token') &&
        `Bearer ${localStorage.getItem('token')}`,
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    });
    if (contentType) {
      headers.append('Content-Type', contentType);
    }
    const config = {
      method: method.toUpperCase(),
      headers,
    };
    if (data) {
      config.body =
        contentType === 'application/json' ? JSON.stringify(data) : data;
    }
    try {
      const res = await fetch(`${API_BASE}${path}`, config);
      if (res.headers.get('Content-Type').indexOf('application/json') > -1) {
        const resData = await res.json();
        return resData;
      }
      const resData = await res.blob();
      return resData;
    } catch (error) {
      throw this.fetchErrorHandler(error);
    }
  };

  fetchErrorHandler = error => {
    switch (error.message) {
      case 'Failed to fetch':
        return {
          status: 'fetch error',
          message: `Couldn't get information from API server!`,
        };
      case 'NetworkError when attempting to fetch resource.':
        return {
          status: 'fetch error',
          message: `Couldn't get information from API server!`,
        };
      default:
        return { status: 'fetch error', message: error.toString() };
    }
  };

  // METHODS

  getData = path => this.httpRequest(path, 'GET');

  postData = (path, data, contentType) =>
    this.httpRequest(path, 'POST', data, contentType);

  delData = path => this.httpRequest(path, 'DELETE');

  // GET

  getUser = () => this.getData(API_USER);

  getLanguages = () => this.getData(API_LANGUAGES);

  getFiles = () => this.getData(API_FILES);

  getFileContent = (id, partId) => {
    if (partId) {
      return this.getData(`${API_FILES}/${id}/${partId}`);
    }
    return this.getData(`${API_FILES}/${id}`);
  };

  getFileDownload = (id, title) => {
    const res = this.httpRequest(`/download/${id}`, 'GET').then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const DOMlink = document.createElement('a');
      DOMlink.href = url;
      DOMlink.setAttribute('download', `${title}.html`);
      document.body.appendChild(DOMlink);
      DOMlink.click();
      DOMlink.parentNode.removeChild(DOMlink);
    });
    return res;
  };
  // POST

  signIn = signInData => this.postData(API_USER, signInData);

  uploadFile = fileData => this.postData(API_FILES, fileData, false);

  // DELETE

  delFile = fileId => this.delData(`${API_FILES}/${fileId}`);
}
