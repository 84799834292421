import React, { Component } from 'react';
import { connect } from 'react-redux';
import WithService from '../../components/WithService';
import {
  fetchLanguages,
  fetchUpload,
  addUrlInput,
  setUrlInput,
  delUrlInput,
  setFileInput,
  clearUrlInput,
  claerAllUrlInputs,
  changeLanguageSelect,
} from '../../redux/actions';
import compose from '../../utils';
import Spinner from '../../components/Spinner';
import ErrorViewer from '../../components/ErrorViewer';
import FileUpload from '../../components/FileUpload';

const mapStateToProps = ({
  languageList: { languages, pending, error },
  fileList: { urlField, fileField, selectedLanguages },
}) => ({
  languages,
  pending,
  error,
  urlField,
  fileField,
  selectedLanguages,
});

const mapDispatchToProps = (dispatch, { api }) => ({
  getLanguages: fetchLanguages(api, dispatch),
  onUpload: data => dispatch(fetchUpload(api, dispatch, data)),
  onAddUrlInput: () => dispatch(addUrlInput()),
  onSetUrlInput: (id, event) => dispatch(setUrlInput(id, event.target.value)),
  onDelUrlInput: id => dispatch(delUrlInput(id)),
  onSetFileInput: blob => dispatch(setFileInput(blob)),
  onClearUrlInput: id => dispatch(clearUrlInput(id)),
  onClaerAllUrlInputs: () => dispatch(claerAllUrlInputs()),
  onChangeLanguageSelect: event => changeLanguageSelect(dispatch, event),
});

class FileUploadContainer extends Component {
  componentDidMount() {
    const { getLanguages } = this.props;
    getLanguages();
  }

  render() {
    const {
      onUpload,
      onAddUrlInput,
      onSetUrlInput,
      onDelUrlInput,
      onSetFileInput,
      onClearUrlInput,
      onClaerAllUrlInputs,
      onChangeLanguageSelect,
      urlField,
      fileField,
      languages,
      defaultLanguages,
      selectedLanguages,
      pending,
      error,
    } = this.props;
    if (pending) {
      return <Spinner />;
    }
    if (error) {
      return <ErrorViewer error={error} />;
    }
    return (
      <FileUpload
        onAddUrlInput={onAddUrlInput}
        onSetUrlInput={onSetUrlInput}
        onDelUrlInput={onDelUrlInput}
        onSetFileInput={onSetFileInput}
        onClearUrlInput={onClearUrlInput}
        onClaerAllUrlInputs={onClaerAllUrlInputs}
        onUpload={onUpload}
        urlField={urlField}
        fileField={fileField}
        languages={languages}
        defaultLanguages={defaultLanguages}
        selectedLanguages={selectedLanguages}
        onChangeLanguageSelect={onChangeLanguageSelect}
      />
    );
  }
}

export default compose(
  WithService(),
  connect(mapStateToProps, mapDispatchToProps),
)(FileUploadContainer);
