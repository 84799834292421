import React from 'react';
import { Alert } from 'react-bootstrap';
import { FaRegDizzy } from 'react-icons/fa';

const ErrorViewer = ({ error }) => (
  <div className="container position-absolute" style={{ margin: '0 -15px' }}>
    <Alert
      variant="danger"
      onClose={() => document.location.reload(true)}
      dismissible
      style={{ backgroundColor: 'rgba(243, 219, 217, .8)' }}
    >
      <FaRegDizzy className="align-middle" size="1.5em" />
      &nbsp;
      <span className="align-middle">
        <strong>
          {error}
          {!error && 'Error!'}
        </strong>
      </span>
    </Alert>
  </div>
);

export default ErrorViewer;
