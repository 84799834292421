import React, { Component } from 'react';
import { connect } from 'react-redux';
import WithService from '../../components/WithService';
import compose from '../../utils';
import { fetchUser } from '../../redux/actions';
import './App.scss';
import Spinner from '../../components/Spinner';
import Home from '../../components/Home';

const mapStateToProps = ({ user, fileViewer }) => ({
  user,
  fileViewer,
});

const mapDispatchToProps = (dispatch, { api }) => ({
  getUser: fetchUser(api, dispatch),
});

class App extends Component {
  componentDidMount() {
    const { user, getUser } = this.props;
    if (user.token) {
      getUser();
    }
  }

  render() {
    const { user, fileViewer } = this.props;
    const { pending } = user;
    const { opened } = fileViewer;

    if (pending) {
      return <Spinner />;
    }

    return <Home user={user} viewer={opened} />;
  }
}

export default compose(
  WithService(),
  connect(mapStateToProps, mapDispatchToProps),
)(App);
