import React from 'react';
import { FaRegEye, FaRegSave, FaRegTrashAlt } from 'react-icons/fa';
import { Button } from 'react-bootstrap';

const FileButtons = React.memo(
  ({
    onOpenFileViewer,
    downloadFile,
    onOpenFileDeleteModal,
    status,
    id,
    title,
  }) => (
    <>
      <Button
        variant="info"
        className="mr-2"
        disabled={status !== 'processed' && status !== 'processing'}
        onClick={() => onOpenFileViewer(id)}
      >
        <FaRegEye />
      </Button>

      <Button
        variant="success"
        className="mr-2"
        disabled={status !== 'processed'}
        onClick={() => downloadFile(id, title)}
      >
        <FaRegSave />
      </Button>
      <Button
        variant="danger"
        onClick={() => onOpenFileDeleteModal(id)}
        disabled={status === 'deleting'}
      >
        <FaRegTrashAlt />
      </Button>
    </>
  ),
);

export default FileButtons;
