// API URL
export const API_BASE =
  process.env.REACT_APP_API_BASE || `${window.location.origin}:8081`;

// API URI
export const API_USER = '/user';
export const API_LANGUAGES = '/languages';
export const API_FILES = '/files';

// AUTH
export const DISABLE_AUTH = !!process.env.REACT_APP_DISABLE_AUTH;
