import React from 'react';
import { Button, ButtonGroup, Col, Form, Row } from 'react-bootstrap';
import FileStatus from '../FileStatus/FileStatus';

const ViewerToolbar = ({
  onToggleHighlight,
  onCloseFileViewer,
  onFontSizePlus,
  onFontSizeMinus,
  onFontSizeReset,
  onJumpPrevMark,
  onJumpNextMark,
  title,
  status,
  progress,
}) => (
  <div className="mb-2 p-3 bg-white border rounded sticky-top">
    <Row className="justify-content-between">
      <Col xs={8} sm={9} md={10} lg={10} xl={11}>
        <div className="text-truncate mb-2">
          <span className="h4">{title}</span>
        </div>
      </Col>
      <Col xs="auto">
        <div className="mb-2 push-right">
          <Button size="sm" onClick={onCloseFileViewer}>
            Close
          </Button>
        </div>
      </Col>
    </Row>
    <Row>
      <Col xs="auto">
        <ButtonGroup className="mb-2 mr-3" size="sm" aria-label="First group">
          <Button onClick={onFontSizeMinus}>Size -</Button>
          <Button onClick={onFontSizePlus}>Size +</Button>
          <Button onClick={onFontSizeReset}>Reset</Button>
        </ButtonGroup>
        <ButtonGroup className="mb-2 mr-3" size="sm" aria-label="Second group">
          <Button onClick={onJumpPrevMark}>Prev</Button>
          <Button onClick={onJumpNextMark}>Next</Button>
        </ButtonGroup>
      </Col>
      <Col>
        <div className="mb-2">
          <FileStatus status={status} progress={progress} />
        </div>
      </Col>
      <Col xs="auto">
        <div className="ml-auto mr-3 mb-2">
          <Form.Check
            type="switch"
            id="custom-switch"
            label="Highlight"
            defaultChecked
            onChange={onToggleHighlight}
          />
        </div>
      </Col>
    </Row>
  </div>
);

export default ViewerToolbar;
