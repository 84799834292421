/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

const initialState = {
  urlField: [
    {
      id: uuid(),
      url: '',
    },
  ],
  fileField: '',
  languages: [],
  selectedLanguages: [],
  files: {},
  searchField: '',
  deleteModal: false,
  pending: false,
  error: false,
};

const updateFileList = createReducer(initialState, {
  FETCH_FILES_REQUEST: state => {
    state.pending = true;
  },
  FETCH_FILES_SUCCESS: (state, action) => {
    state.files = action.payload.data;
    state.pending = false;
    state.error = action.payload.status === 'error' && action.payload.message;
  },
  FETCH_FILES_FAILURE: (state, action) => {
    state.pending = false;
    state.error = action.payload.message;
  },
  ADD_URL_FIELD: state => {
    state.urlField = [
      ...state.urlField,
      {
        id: uuid(),
        url: '',
      },
    ];
  },
  CHANGE_URL_FIELD: (state, action) => {
    const { id, url } = action.payload;
    const i = state.urlField.findIndex(val => val.id === id);
    state.urlField[i].url = url;
  },
  DEL_URL_FIELD: (state, action) => {
    const id = action.payload;
    const i = state.urlField.findIndex(val => val.id === id);
    state.urlField.splice(i, 1);
  },
  CHANGE_FILE_FIELD: (state, action) => {
    state.urlField[0].url = '';
    state.fileField = action.payload;
  },
  CLEAR_FIELD: (state, action) => {
    const id = action.payload;
    const i = state.urlField.findIndex(val => val.id === id);
    state.urlField[i].url = '';
    if (i === 0) {
      state.fileField = '';
    }
  },
  CLEAR_ALL_URL_INPUTS: state => {
    state.urlField = [
      {
        id: uuid(),
        url: '',
      },
    ];
    state.fileField = '';
  },
  CHECK_LANGUAGE_SELECT: (state, action) => {
    state.selectedLanguages = [action.payload];
  },
  UNCHECK_LANGUAGE_SELECT: state => state,
  CHANGE_FILE_SEARCH_FIELD: (state, action) => {
    state.searchField = action.payload;
  },
  FETCH_FILE_DOWNLOAD: state => state,
  OPEN_FILE_DELETE_MODAL: (state, action) => {
    state.deleteModal = action.payload;
  },
  CLOSE_FILE_DELETE_MODAL: state => {
    state.deleteModal = false;
  },
  SET_FILE_DELETING_STATUS: (state, action) => {
    state.files[action.payload].status = 'deleting';
  },
});

export default updateFileList;
