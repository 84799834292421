const userRequested = () => ({
  type: 'FETCH_USER_REQUEST',
});

const userLoaded = user => {
  if (user.data && user.data.token) {
    localStorage.setItem('token', user.data.token);
  }
  return {
    type: 'FETCH_USER_SUCCESS',
    payload: user,
  };
};

const userError = error => ({
  type: 'FETCH_USER_FAILURE',
  payload: error,
});

const fetchUserSignIn = (api, dispatch, signInData) => async () => {
  dispatch(userRequested());
  try {
    const data = await api.signIn(signInData);
    dispatch(userLoaded(data));
  } catch (error) {
    dispatch(userError(error));
  }
};

const fetchUser = (api, dispatch) => async () => {
  dispatch(userRequested());
  try {
    const data = await api.getUser();
    dispatch(userLoaded(data));
  } catch (error) {
    dispatch(userError(error));
  }
};

export const setUserPasswordInput = text => ({
  type: 'CHANGE_USER_PASSWORD_FIELD',
  payload: text,
});

const userSignOut = () => {
  localStorage.removeItem('token');
  return {
    type: 'USER_SIGN_OUT',
  };
};

export { fetchUser, fetchUserSignIn, userSignOut };
